<template>
  <div>
    <v-app-bar app fixed color="white" class="appbar prevent-select">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        color="grey"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        class="appbar__title subtitle-1"
        style="font-weight: 600"
        id="robo-font"
      >
        <v-img
          style="margin: auto; position: absolute; left: -2px; top: 4px"
          :contain="true"
          width="20px"
          height="auto"
          :src="getChurchLogo"
        ></v-img
        >{{
          $store.getters["userStore/userData"].church?.fullName
        }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-badge
        v-if="config.notifications?.polls?.length"
        :content="config.notifications?.polls?.length"
        color="#52c2cd"
        :overlap="true"
        class="mr-3"
      >
        <v-icon @click="openPolls()">mdi-poll</v-icon>
      </v-badge>
      <v-badge
        v-if="config.notifications.count"
        :content="config.notifications.count"
        color="#52c2cd"
        :overlap="true"
        class="notification-badge"
      >
        <v-icon @click="openNotifications()">mdi-bell-outline</v-icon>
      </v-badge>
      <v-icon v-else @click="openNotifications()">mdi-bell-outline</v-icon>
      <v-badge :overlap="true" color="#C0C0C0" icon="mdi-star" class="ml-3">
        <v-icon @click="handleTopBarIconClick('Profile')">mdi-account</v-icon>
      </v-badge>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer">
      <v-img :aspect-ratio="16 / 9" src="">
        <v-row
          align="end"
          class="lightbox white--text pa-2 fill-height"
          style="color: #000 !important; background: #f1f1f1"
        >
          <v-col class="ml-2">
            <div class="subheading text-capitalize overline">
              {{ $store.getters["userStore/userData"].name }}
            </div>
            <div class="body-1 overline">
              {{ $store.getters["userStore/userData"].email }}
            </div>
          </v-col>
        </v-row>
      </v-img>

      <v-list v-if="getSideMenu">
        <template v-for="(item, i) in getSideMenu">
          <v-divider v-if="item.divider" :key="i"></v-divider>
          <v-list-item v-else :key="item.title">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title
              class="text-capitalize"
              @click="
                handleNavClick({
                  name: item.routeName,
                  params: {
                    type: item.title,
                  },
                })
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </template>
        <!-- <v-list-item v-if="!isSettingsDisabled">
          <v-list-item-action>
            <v-icon>mdi-tune</v-icon>
          </v-list-item-action>
          <v-list-item-title @click="settings()">Settings</v-list-item-title>
        </v-list-item> -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-title @click="logoutUser()">Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <notifications
      @close="handleNotificationClose"
      @update="handleUpdate"
      :config="{
        ...notifications,
        notifications: config.notifications.notices,
        events: config.notifications.myEvents,
        bookmarks: config.notifications.bookmarks,
        prayers: config.notifications.prayers,
      }"
    />
    <polls
      v-if="polls.enable"
      @close="polls.enable = false"
      :config="{
        ...polls,
        polls: config.polls,
        componentName: 'poll-modal',
      }"
    />
  </div>
</template>
<script>
import appCommonMixin from "@/_core/mixins/appCommonMixin";

export default {
  name: "appbar",
  components: {
    notifications: () => import("@/components/Notifications"),
    polls: () => import("@/components/Polls/Polls"),
  },
  mixins: [appCommonMixin],
  data() {
    return {
      isSettingsDisabled: true,
      showMenu: false,
      drawer: null,
      swiperOption: {
        slidesPerView: 3.5,
        spaceBetween: 0,
        freeMode: true,
        drawer: false,
      },
      notifications: {
        enable: false,
      },
      polls: {
        enable: false,
      },
      items: [],
    };
  },
  props: {
    config: {
      type: Object,
    },
  },
  watch: {
    config: {
      handler(val) {
        // console.log(val);
      },
      deep: true,
    },
  },
  created() {},
  computed: {
    getSideMenu() {
      return this.$store.getters["appStore/appData"].sidemenu;
    },
  },
  methods: {
    openPolls() {
      this.polls.enable = !this.polls.enable;
    },
    handleUpdate() {
      this.config.notifications.count =
        this.config.notifications.count > 0
          ? this.config.notifications.count - 1
          : 0;
    },
    handleNotificationClose() {
      this.notifications.enable = false;
    },
    handlePollClose() {
      this.notifications.enable = false;
    },
    settings() {
      return true;
    },
    openNotifications() {
      this.notifications.enable = !this.notifications.enable;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleTopBarIconClick(routeName) {
      this.$router
        .push({
          name: routeName,
        })
        .catch((e) => e);
    },
    handleNavClick(routeObj) {
      const exceptionRoutes = ["my-messages", "accounts"];
      const hyphenatedRoute = routeObj.params.type
        .toLowerCase()
        .replace(/ /g, "-");

      this.$router
        .push({
          ...routeObj,
          ...(exceptionRoutes.includes(hyphenatedRoute) && {
            name: hyphenatedRoute,
          }),
        })
        .catch((e) => e);
      this.drawer = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-navigation-drawer__content {
  z-index: 999;
}
.appbar {
  box-shadow: none !important;
  text-align: center;
  &__title {
    position: relative;
    color: #333;
  }
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: #6b6b6b;
}
.v-badge__badge {
  background: #000 !important;
}
</style>
@/_core/mixins/socketMixin