import { mapState } from 'vuex'

const toastMixin = {
    data() {
        return {
            alertMessage: undefined,
            loaderEnabled: false,
            inProgress: false
        }
    },
    computed: {
        ...mapState('appStore', ['appData'])
    },
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'appStore/updateAppData') {
                this.inProgress = state.appStore.appData.inProgress
                if (state.appStore.appData.message) {
                    this.alertMessage = { active: true, ...state.appStore.appData.message, className: 'overline' }
                }
            }
        })
    }
}

export default toastMixin
