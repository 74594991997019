<template>
  <v-sheet
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      max-width="300"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>
</template>

<script>
export default {
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
};
</script>