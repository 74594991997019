import { Api } from '@/_core/services/api'
import handleError from '@/_core/helpers/errorHandler'
import moment from "moment";

export default {
    getChurchByCode(code) {
        return Api()
            .get(`ext/church-public-info/${code}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    registerUser({ code, ...form }) {
        return Api()
            .post(`ext/register-user/${code}`, form)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    initPasswordReset(payload, hideError = false) {
        return Api()
            .post(`ext/reset-password`, payload)
            .then(({ data }) => data)
            .catch(e => {
                if (!hideError) {
                    handleError(e)
                }
            })
    },
    signInUser(payload) {
        return Api()
            .post(`ext/sign-in`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    verifyOtp(authObj) {
        return Api()
            .post('ext/verify-otp', { ...authObj })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updatePassword(authObj) {
        return Api()
            .post('ext/update-password', { ...authObj })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getUserInfo() {
        return Api()
            .get(`ext/user-info/`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    deleteUser(payload) {
        return Api()
            .post(`ext/delete-user/`, { id: payload })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    activateAccount(payloadObj) {
        return Api()
            .post(`ext/activate-user/`, payloadObj)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updateNotifictionSettings(payloadObj) {
        return Api()
            .post(`ext/update-setting/`, payloadObj)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updateApp() {
        return Api()
            .post(`ext/update-app/`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    revokeAccount(payloadObj) {
        return Api()
            .post(`ext/revoke-user/`, payloadObj)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getEvents({ type = 'events', name = '', status = 'published', limit = 10, offset = 0 } = {}) {
        return Api()
            .get(`ext/${type}/${name}?date=${moment().format('DD-MM-YY h:mm a')}&status=${status}&limit=${limit}&offset=${offset}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getPolls({ type = 'polls', isPublic = false, name = '', status = 'published', limit = 10, offset = 0 } = {}) {
        return Api()
            .get(`ext/${type}/${name}?public=${isPublic}&date=${moment().format('DD-MM-YY h:mm a')}&status=${status}&limit=${limit}&offset=${offset}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getSermons({ type = 'sermons', tags = '', name = '', status = 'published', limit = 10, offset = 0 } = {}) {
        return Api()
            .get(`ext/${type}/${name}?publictags=${tags}&date=${moment().format('DD-MM-YY h:mm a')}&status=${status}&limit=${limit}&offset=${offset}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    attendEvent(eventId) {
        return Api()
            .post(`ext/attend-event/`, { eventId })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    addComment(id, payload, type) {
        return Api()
            .post(`ext/comment/${id}?type=${type}`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    removeUserFromEvent(id) {
        return Api()
            .post(`ext/remove-event/`, { id })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    myEvents() {
        return Api()
            .get(`ext/my-events/`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updateEvent(id, payload) {
        return Api()
            .put(`ext/update-activity/${id}`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    addEvent(payload) {
        return Api()
            .post(`ext/update-activity/`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    deleteEvent(id) {
        return Api()
            .post(`ext/delete-activity/`, { ids: [id] })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    manageEvent(id, payload) {
        return Api()
            .post(`ext/manage-activity/${id}`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getLatLong(address) {
        return Api()
            .post(`vendor/geocode/`, { address })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getDailyMessages({ name = '', status = 'published', offset = 0, limit = 10 } = {}) {
        return Api()
            .get(`ext/daily-message/${name}?status=${status}&offset=${offset}&limit=${limit}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getArticles({ type = 'articles', name = '', status = 'published', offset = 0, limit = 10 } = {}) {
        return Api()
            .get(`ext/${type}/${encodeURIComponent(name)}?status=${status}&offset=${offset}&limit=${limit}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    deleteArticle(id) {
        return Api()
            .post(`ext/delete-activity/`, { ids: [id] })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getResources(name = '') {
        return Api()
            .get(`ext/resources/${name}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getNews(name = '') {
        return Api()
            .get(`ext/news/${name}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getGroups({ name = '', status = '', limit = 10, offset = 0 } = {}) {
        return Api()
            .get(`ext/groups/${name}?status=${status}&limit=${limit}&offset=${offset}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getChats(id = '') {
        return Api()
            .get(`ext/chats/${id}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getAllChats() {
        return Api()
            .get(`ext/all-chats`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updateConversation({ id, payload }) {
        return Api()
            .post(`ext/chats/${id}`, { message: payload })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getPrayers({ name = '', status = 'published', offset = 0, limit = 10 } = {}) {
        return Api()
            .get(`ext/prayers/${name}?status=${status}&offset=${offset}&limit=${limit}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getNotifications({ name = '', status = 'active', offset = 0, limit = 0 } = {}) {
        return Api()
            .get(`ext/notifications/${name}?status=${status}&offset=${offset}&limit=${limit}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    addGroup(payload) {
        return Api()
            .post(`ext/groups/`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updateGroup(id, payload) {
        return Api()
            .put(`ext/groups/${id}`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    deleteGroup(id) {
        return Api()
            .post(`ext/delete-group/`, { ids: [id] })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    manageGroup(id, payload) {
        return Api()
            .post(`ext/manage-group/${id}`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getAboutContent(name = '') {
        return Api()
            .get(`ext/about/${name}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getSubscriptions({ limit = 100, offset = 0 } = {}) {
        return Api()
            .get(`ext/subscriptions/?limit=${limit}&offset=${offset}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getPromiseVerse(reference = '') {
        return Api()
            .get(`vendor/promise-verse/${reference}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getPromiseVerseHome(reference = '') {
        return Api()
            .get(`vendor/promise-verse/${reference}`)
            .then(({ data }) => {
                return { items: data }
            })
            .catch(e => handleError(e))
    },
    getImageText(image = '') {
        return Api()
            .post(`vendor/images/`, { image })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    likeActivity(id, type) {
        return Api()
            .get(`ext/like-activity/${id}?type=${type}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updatePromiseVerse(promiseVerse) {
        return Api()
            .post(`ext/promise-verse/`, { promiseVerse })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    addBookmark({ status, ...payloadObj }) {
        return Api()
            .post(`ext/add-bookmark?type=${status}`, payloadObj)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    removeBookMark(id) {
        return Api()
            .post(`ext/remove-bookmark/`, { id })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    searchEvents({ name = '', searchKey = '', status = 'published', limit = 10, offset = 0 } = {}) {
        return Api()
            .get(`ext/events/${name}?&searchKey=${searchKey}&status=${status}&limit=${limit}&offset=${offset}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getAllUsers({ roles = '', offset = 0, limit = 10, disableProgess = false } = {}) {
        return Api({ disableProgess })
            .get(`ext/members/?roles=${roles}&limit=${limit}&offset=${offset}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getMemberInfo(guid) {
        return Api()
            .get(`ext/member-info/${guid}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getRelationByPhone(userObj, hideError = false) {
        return Api()
            .post(`ext/relation-by-phone/`, userObj)
            .then(({ data }) => data)
            .catch(e => {
                if (!hideError) {
                    handleError(e)
                }
            })
    },
    addAccountEntry(accountObj) {
        return Api()
            .post(`ext/accounts/`, accountObj)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    getAccountEntries({ limit = 10, offset = 0, name = '' } = {}) {
        return Api()
            .get(`ext/accounts/?name=${name}&limit=${limit}&offset=${offset}`)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updateAccountEntry(id) {
        return Api()
            .put(`ext/accounts/${id}`, { reject: true })
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
    updateUserBasicInfo(payload) {
        return Api()
            .put(`ext/update-basic-info/`, payload)
            .then(({ data }) => data)
            .catch(e => handleError(e))
    },
}