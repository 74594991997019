import Vue from 'vue'
import Vuex from 'vuex'
import appStore from './modules/appStore'
import userStore from './modules/userStore'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appStore,
    userStore
  },
  plugins: [new VuexPersistence().plugin]
})
