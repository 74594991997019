import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from "@/_core/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      redirectOnFirstTimeUse: true,
    }
  },
  {
    path: '/get-started/:churchCode?',
    name: 'Getstarted',
    component: () => import('@/views/Getstarted.vue'),
    meta: {
      requiresAuth: true,
      isAppBarInactive: true
    }
  },
  {
    path: '/login/:churchCode?',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register/:churchCode',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
    meta: {
      isAppBarInactive: true,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/discover',
    name: 'Discover',
    component: () => import('@/views/Discover.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('@/views/Groups.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/Events.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contribute',
    name: 'Contribute',
    component: () => import('@/views/Contribute.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('@/views/Accounts/Accounts.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/subscriptions',
    name: 'accountSubscriptions',
    component: () => import('@/views/Accounts/Subscription.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accounts/add/:userId?',
    name: 'addAccount',
    component: () => import('@/views/Accounts/Add.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('@/views/Resources.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/content/:type?/:slug?',
    name: 'Content',
    component: () => import('@/views/MessageDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sermon/:type?/:slug?',
    name: 'Sermon-Detail',
    component: () => import('@/views/SermonDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/event-detail/:type?/:slug?',
    name: 'Event-Detail',
    component: () => import('@/views/EventDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/directory',
    name: 'Directory',
    component: () => import('@/views/Directory.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/leadership',
    name: 'Leadership',
    component: () => import('@/views/Leadership.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/directory-detail/:guid?',
    name: 'Directory-Detail',
    component: () => import('@/views/DirectoryDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/views/Aboutus.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/request-prayer',
    name: 'Prayer',
    component: () => import('@/views/Prayer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/promise-verse',
    name: 'PromiseVerse',
    component: () => import('@/views/Promiseverse.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/poll/:pollTitle?',
    name: 'polls',
    component: () => import('@/components/Polls/Polls.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/my-messages/:groupSlug?',
    name: 'my-messages',
    props: true,
    component: () => import('@/views/GroupDetail.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/update',
    name: 'Update',
    component: () => import('@/views/Update.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/notifications/:type',
    name: 'NotificationList',
    component: () => import('@/views/NotificationList.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/manage/:type?/:action?/:id?',
    name: 'Manage',
    component: () => import('@/views/Manage/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  try {
    const { params: { churchCode }, name } = from
    const { meta } = to
    const redirectExcludedMeta = ['isAppBarInactive']
    const metaMap = {
      requiresAuth: {
        key: 'idToken',
        pathName: '/login'
      },
      redirectOnFirstTimeUse: {
        key: 'initiated',
        pathName: '/get-started'
      },
    }
    const metaResponse = Object.keys(meta).filter(e => !redirectExcludedMeta.includes(e)).filter(m => !store.getters['userStore/userData'][metaMap[m].key])
    
    store.dispatch("appStore/setAppData", { historyPath: name })
    if (metaResponse && metaResponse.length) {
      if (!store.getters['userStore/userData'][metaResponse[0]]) {
        router.push({
          path: `${metaMap[metaResponse[0]].pathName}/${churchCode || ''}`,
        })
        return
      } else {
        next()
      }
    } else {
      next()
    }
  } catch (e) {
    console.log(`Error in router beforeEach, ${e}`);
  }
})

export default router
