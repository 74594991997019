<template>
  <div></div>
</template>

<script>
export default {
  name: "versecard",
  data() {
    return {
      canvas: undefined,
      canvasWidth: 400,
      canvasHeight: 400,
      backgroundImageUrl: "", // To store the URL of the background image
    };
  },
  props: {
    config: {
      type: Object,
      default: {},
    },
  },
  watch: {
    config: {
      immediate: true,
      handler(val, oldVal) {
        if (val.enable) {
          setTimeout(() => {
            this.init();
          }, 1000)
        }
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetchBackgroundImage();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    init() {
      this.canvas = document.createElement("canvas");
      const ctx = this.canvas.getContext("2d");
      const img = new Image();
      const logo = new Image();

      img.crossOrigin = "anonymous";

      const scale = window.devicePixelRatio;

      this.canvas.width = Math.floor(this.canvasWidth * scale);
      this.canvas.height = Math.floor(this.canvasHeight * scale);

      this.drawPlaceholder(this.canvas, ctx, img, logo);
    },

    drawPlaceholder(canvas, ctx, img, logo) {
      img.onload = () => {
        this.drawOverlay(canvas, img, ctx, logo);
        this.drawText(img, ctx);
      };
      logo.src = require("@/assets/fgck-logo.png");
      img.src = this.backgroundImageUrl; // Use fetched background image URL
    },

    drawOverlay(canvas, img, ctx, logo) {
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        canvas.width,
        canvas.height
      );
      ctx.drawImage(logo, 8, 9, 60, 60);
      ctx.fillStyle = "rgba(30, 144, 255, 0)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.strokeStyle = "#FFF";
      ctx.strokeRect(10, 10, canvas.width - 20, canvas.height - 20);
    },

    drawText(img, ctx) {
      var scale = window.devicePixelRatio;
      ctx.scale(scale, scale);
      ctx.fillStyle = "#fafafa";
      ctx.textBaseline = "start";
      ctx.font = "20px 'Roboto'";
      ctx.shadowColor = "rgba(0, 0, 0, 0.5)"; // Shadow color
      ctx.shadowOffsetX = 2; // Shadow offset X
      ctx.shadowOffsetY = 5; // Shadow offset Y
      ctx.shadowBlur = 4; // Shadow blur
      ctx.fillText(this.config.churchName, 30, 25);

      ctx.fillStyle = "white";
      ctx.textBaseline = "middle";
      ctx.font = "bold 25px 'Caveat'";
      ctx.lineHeight = 30; // Adjust line height as needed
      ctx.letterSpacing = 1; // Adjust letter spacing as needed
      ctx.shadowColor = "rgba(0, 0, 0, 0.5)"; // Shadow color
      ctx.shadowOffsetX = 2; // Shadow offset X
      ctx.shadowOffsetY = 2; // Shadow offset Y
      ctx.shadowBlur = 4; // Shadow blur
      // Splitting text into lines to fit within canvas width
      const maxWidth = this.canvasWidth - 60; // Adjust as needed
      const lines = this.wrapText(ctx, this.config.verse, maxWidth);
      // Determining vertical position of text
      const textHeight = lines.length * 30;
      const verticalPosition = Math.max(
        (this.canvasHeight - textHeight) / 2,
        100
      );

      lines.forEach((line, index) => {
        ctx.fillText(line, 20, verticalPosition + index * 30);
      });

      ctx.fillText(
        this.config.reference,
        20,
        verticalPosition + textHeight + 30
      );

      this.$emit("update", this.canvas.toDataURL("png"));
    },

    wrapText(ctx, text, maxWidth) {
      const words = text.split(" ");
      let lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = ctx.measureText(currentLine + " " + word).width;
        if (width < maxWidth) {
          currentLine += " " + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
      return lines;
    },
    handleResize() {
      const parentWidth = window.innerWidth;
      const parentHeight = window.innerHeight;
      const minSize = Math.min(parentWidth, parentHeight);
      this.canvasWidth = minSize;
      this.canvasHeight = minSize;
      this.init();
    },
    async fetchBackgroundImage() {
      try {
        const response = await fetch(
          `https://api.unsplash.com/photos/random?query=nature&client_id=6X_rYDtnfa_YM7njGICPzNyIySpPqnozqFeTFG-E8AM`
        );
        const data = await response.json();
        this.backgroundImageUrl = data.urls.regular;
      } catch (error) {
        console.error("Error fetching background image:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* You can keep your styling here if needed */
</style>
