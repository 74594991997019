<template>
  <div>
    <v-app v-if="isMobileDeviceActive">
      <div class="pull-to-refresh" ref="pull-to-refresh">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <app-bar
        v-if="isLoggedIn && isAppBarActive"
        :config="{ notifications: setNotifications, polls: setNotifications.polls }"
      ></app-bar>
      <v-content id="contentHolder">
        <toast :config="alertMessage" @outside="handleOutsideClick" />
        <keep-alive>
          <router-view />
        </keep-alive>
      </v-content>
      <v-progress-linear indeterminate v-if="inProgress"></v-progress-linear>
      <bottom-nav v-if="isLoggedIn && isAppBarActive"></bottom-nav>
    </v-app>
    <v-app v-else>
      <v-row align="center" justify="center" class="text-center">
        <v-col>
          <span class="overline"
            >This site is best viewed in mobile device</span
          >
        </v-col>
      </v-row>
      <v-snackbar v-model="pageObj.snackbar.enable" top tile vertical>
        <span class="overline">{{ pageObj.snackbar.message }}</span>

        <template v-slot:action="{ attrs }">
          <v-btn icon v-bind="attrs" @click="pageObj.snackbar.enable = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import appbar from "@/views/common/appbar.vue";
import bottomnav from "@/views/common/bottomNav.vue";
import toastMixin from "@/_core/mixins/toastMixin";
import EventBus from "./_core/util/event";
import Settings from "@/plugins/appSettings";
import io from "socket.io-client";

export default {
  name: "App",
  components: {
    "app-bar": appbar,
    "bottom-nav": bottomnav,
    toast: () => import("@/components/BottomSheet.vue"),
  },
  mixins: [toastMixin],
  data() {
    return {
      routesToUpdate: ["Home", "Events"],
      updatedData: [],
      notifications: {
        items: [],
        count: 0,
      },
      pageObj: {
        snackbar: {
          enable: false,
          message: "You've a new notification"
        },
      },
      subscription: undefined,
      isAppBarActive: true,
      isLoggedIn: false,
    };
  },
  computed: {
    setNotifications() {
      return this.notifications;
    },
  },
  created() {
    this.socket = io(Settings.baseUrl);
    this.socket.on("notification", (data) => {
      if (this.updatedData.indexOf(data.type) === -1) {
        this.updatedData.push(data.type);
        this.$store.dispatch("userStore/setUserData", {
          updated: this.updatedData,
        });

        if(this.routesToUpdate.includes(this.$route.name)) {
          EventBus.$emit("pull-refresh", { type: this.updatedData });
        }
      }
      this.displayNotification({
        title: data.title,
        type: data.type,
        body: data.description?.replace(/<[^>]*>/g, ""),
      });
    });
    this.verifyMobileDevice();
    if (!this.isLoggedIn) {
      this.subscription = this.$store.subscribe((mutation, state) => {
        if (mutation.type === "userStore/updateUserData") {
          if (state.userStore.userData.idToken) {
            this.isLoggedIn = true;
          }
          if (state.userStore.userData.notifications) {
            this.notifications = {
              count:
                state.userStore.userData.notifications.length +
                state.userStore.userData.prayers.length,
              notices: state.userStore.userData.notifications,
              myEvents: state.userStore.userData.myEvents,
              bookmarks: state.userStore.userData.bookmarks,
              prayers: state.userStore.userData.prayers,
              polls: state.userStore.userData.polls,
            };
          }
        }
      });
    } else {
      this.subscription();
    }
  },
  beforeDestroy() {
    this.subscription();
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    displayNotification({ title, body, type }) {
      this.pageObj.snackbar = {
        enable: true,
        message: `${type} - ${title}`
      }

    },
    handleOutsideClick() {
      this.alertMessage = {
        active: false,
        content: "",
      };
    },
    verifyMobileDevice() {
      this.isMobileDeviceActive = window.navigator.userAgent.includes("Mobile");
    },
  },
  watch: {
    $route(route) {
      const isAppBarActive = route.meta.isAppBarInactive;

      this.isAppBarActive = !isAppBarActive;
      this.isLoggedIn = this.$store.getters["userStore/userData"].idToken;

      if (this.routesToUpdate.includes(route.name) && this.updatedData) {
        EventBus.$emit("pull-refresh", { type: this.updatedData });
        this.updatedData = [];
        this.$store.dispatch("userStore/setUserData", {
          updated: [],
        });
      }
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Felipa");
@import url("https://fonts.googleapis.com/css?family=Poiret+One");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

#app {
  font-family: "Source Sans Pro", sans-serif;
  background-color: #fbfbfb;
  text-transform: none;
}
#robo-font {
  font-family: "Roboto Condensed", sans-serif !important;
  text-transform: none;
}
.newLine {
  display: block;
}
h3 {
  color: #333;
}
.v-item-group.v-bottom-navigation {
  box-shadow: none;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.pull-to-refresh {
  position: fixed;
  top: -50px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.01s ease-in-out;
  z-index: 999;
}
.pull-to-refresh.visible {
  top: 60px;
}
</style>
