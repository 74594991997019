<template>
  <v-container>
    <v-row justify="space-between">
      <v-col md12>
        <h3
          style="text-align: left"
          class="mt-5 font-weight-regular text-capitalize"
        >
          Hey, {{ $store.getters["userStore/userData"]?.name }}!
          <v-chip small>{{ $store.getters["appStore/appData"]?.role }}</v-chip>
          <span class="newLine overline" style="text-transform: capitalize"
            >Welcome to
            {{ $store.getters["userStore/userData"].church?.fullName }}</span
          >
        </h3>
        <v-row>
          <v-col cols="12">
            <v-alert
              @click="updateApp"
              v-if="
                parseFloat(
                  $store.getters['userStore/userData'].church?.appVersion
                ) >
                parseFloat(
                  $store.getters['userStore/userData']?.userAppVersion || '0'
                )
              "
              icon="mdi-update"
              prominent
              text
              color="#52c2cd"
              class="mt-2 mb-0 overline"
            >
              Click to Update to new version
              {{ $store.getters["userStore/userData"].church.appVersion }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="mt-2" v-if="dashboardObj.events">
          <swiper
            :slides-per-view="dashboardObj.events.length === 1 ? 1 : 1.2"
            :space-between="dashboardObj.events.length === 1 ? 30 : 15"
            v-if="dashboardObj.events && reRenderSwiper"
          >
            <swiper-slide
              v-for="(event, index) in dashboardObj.events"
              :key="index"
            >
              <v-col
                cols="12"
                :class="{ 'pl-2': dashboardObj.events.length === 1 }"
              >
                <v-card
                  color="#385F73"
                  :width="
                    dashboardObj.events.length === 1
                      ? 'calc(100vw - 20px)'
                      : 'auto'
                  "
                  dark
                  :img="imageUrl[index % 2]"
                  class="event-card prevent-select"
                  style="min-height: 170px"
                  @click="
                    handleEventClick({
                      type: 'events',
                      slug: event.title.replaceAll(' ', '-'),
                    })
                  "
                >
                  <v-card-title class="text-h6">
                    {{ event.title }}
                  </v-card-title>
                  <v-card-subtitle v-if="event.description">
                    {{
                      event.description.length > 65
                        ? `${event.description.substring(0, 50)}...`
                        : event.description
                    }}
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-btn text> Attend </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </swiper-slide>
          </swiper>
        </v-row>
        <Skeleton v-else />
      </v-col>
    </v-row>
    <v-row>
      <v-carousel
        :continuous="false"
        :show-arrows="false"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        height="auto"
      >
        <v-carousel-item v-if="dashboardObj.dailyMessage">
          <v-col md12>
            <h3 style="text-align: left" class="mt-1 font-weight-regular">
              Suggested Reading
            </h3>
            <v-card
              class="mx-auto mt-2"
              color="#52c2cd"
              dark
              max-width="400"
              height="230px"
            >
              <v-card-text
                class="text-h6 font-weight-bold pb-1 pt-2 text-center"
                @click="
                  handleMessageClick({
                    type: 'daily-message',
                    slug: dashboardObj.dailyMessage.title.replaceAll(' ', '-'),
                  })
                "
              >
                {{ dashboardObj.dailyMessage.quotedText }}
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-avatar color="grey darken-3">
                    <v-avatar left>
                      <v-icon text x-small>
                        {{
                          dashboardObj.dailyMessage.author
                            ? dashboardObj.dailyMessage.author.name
                                .match(/[A-Z]/g)
                                .join("")
                            : `${$store.getters[
                                "userStore/userData"
                              ].church?.name
                                .match(/[A-Z]/g)
                                .join("")} author`
                        }}</v-icon
                      >
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      dashboardObj.dailyMessage.author
                        ? dashboardObj.dailyMessage.author.name
                        : `${$store.getters["userStore/userData"].church?.name
                            .match(/[A-Z]/g)
                            .join("")} author`
                    }}</v-list-item-title>
                  </v-list-item-content>

                  <v-row align="center" justify="end">
                    <v-btn
                      icon
                      class="mr-1"
                      @click="
                        likeActivity(dashboardObj.dailyMessage._id, isLiked)
                      "
                      ><v-icon>mdi-thumb-up-outline</v-icon></v-btn
                    >
                    <span class="subheading mr-2">{{
                      dashboardObj.dailyMessage.likeCount
                    }}</span>
                    <span class="mr-1">·</span>
                    <!-- <v-icon class="mr-1"> mdi-share-variant </v-icon> -->
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-carousel-item>
        <v-carousel-item v-if="dashboardObj.dailyVerse">
          <v-col md12>
            <h3 style="text-align: left" class="mt-1 font-weight-regular">
              Daily Verse
            </h3>
            <v-card
              class="mx-auto mt-2"
              color="#1F7087"
              dark
              max-width="400"
              height="230px"
              align="center"
            >
              <v-card-text
                class="font-weight-bold pb-2"
                style="font-size: 1rem; line-height: 24px"
              >
                {{ dashboardObj.dailyVerse?.verse || verse.verse }}
              </v-card-text>
              <v-card-actions class="text-center overline justify-center">
                {{ dashboardObj.dailyVerse?.reference || verse.reference }}
                <v-icon class="ml-3" @click="activateShare = true"
                  >mdi-share-variant</v-icon
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-carousel-item>
      </v-carousel>
      <v-col v-if="pageObj.isServiceCallActive">
        <Skeleton />
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col md12 v-if="dashboardObj.articles">
        <h3 style="text-align: left" class="mt-0 font-weight-regular">
          From FGCK Community
        </h3>
        <v-row class="mt-1">
          <swiper
            :slides-per-view="dashboardObj.articles.length === 1 ? 1 : 1.2"
            :space-between="dashboardObj.articles.length === 1 ? 30 : 15"
            v-if="dashboardObj.articles && reRenderSwiper"
          >
            <swiper-slide v-for="(article, index) in getArticles" :key="index">
              <v-col
                cols="12"
                :class="{
                  'pl-2': dashboardObj.articles.length === 1,
                  'mr-5': dashboardObj.articles.length > 1,
                }"
              >
                <v-card
                  class="mx-auto"
                  max-width="400"
                  :width="
                    dashboardObj.articles.length === 1
                      ? 'calc(100vw - 35px)'
                      : 'auto'
                  "
                  height="300px"
                  @click="
                    handleMessageClick({
                      type: 'articles',
                      slug: article.title.replaceAll(' ', '-'),
                    })
                  "
                >
                  <v-img
                    class="white--text align-end"
                    height="200px"
                    :src="
                      article.displayImage ||
                      require('../assets/images/docks.jpeg')
                    "
                  >
                    <template slot:placeholder>
                      <v-chip
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        v-if="article.tags"
                        small
                        style="position: absolute; top: 0; right: 10px"
                      >
                        {{ article.tags?.toString() }}
                        <v-icon right small> mdi-star </v-icon>
                      </v-chip>
                    </template>
                    <v-card-text class="text--white">
                      <div class="article-quotedText">
                        {{ article.quotedText }}
                      </div>
                    </v-card-text>
                  </v-img>
                  <v-card-title
                    class="overline mb-5"
                    style="word-break: break-word"
                    >{{ article.title }}</v-card-title
                  >
                </v-card>
              </v-col>
            </swiper-slide>
          </swiper>
        </v-row>
      </v-col>
      <v-col cols="12" v-else>
        <Skeleton />
      </v-col>
    </v-row>
    <VerseCard
      v-if="activateShare"
      @update="handleShare"
      :config="{
        churchName: getChurchName,
        enable: activateShare,
        ...dashboardObj.dailyVerse,
      }"
    />
    <pop-up
      v-if="$route.name === 'home'"
      :config="{
        enable: dateInjected,
        title: 'Word of the Day',
        share: true,
      }"
      @close="(pageObj.modal.enable = false), setDailyVerseDate()"
      @share="activateShare = true"
    >
      <div slot="body">
        <v-card flat color="transparent">
          <v-card-text>
            <p class="text-h6 text--primary">
              {{ dashboardObj.dailyVerse.reference }}
            </p>
            <div class="text--primary">
              {{ dashboardObj.dailyVerse.verse }}
            </div>
          </v-card-text>
        </v-card>
      </div>
    </pop-up>
  </v-container>
</template>

<script>
import appCommonMixin from "@/_core/mixins/appCommonMixin";
import moment from "moment";
import VerseCard from "@/components/ImageCard/VerseCard.vue";
import store from "@/_core/store";
import commonService from "@/_core/services/commonService";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import Skeleton from "@/components/skeleton.vue";
import EventBus from "@/_core/util/event";

export default {
  name: "Home",
  mixins: [appCommonMixin],
  components: {
    Swiper,
    SwiperSlide,
    Skeleton,
    VerseCard,
    "pop-up": () => import("@/components/Modal.vue"),
  },
  data() {
    return {
      reRenderSwiper: true,
      activateShare: false,
      pageObj: {
        modal: {
          enable: false,
        },
        isServiceCallActive: false,
      },
      dashboardObj: {
        isGetStartedActive: false,
        events: undefined,
        dailyMessage: undefined,
        dailyVerse: undefined,
      },
      imageUrl: [
        require("@/assets/images/card-event-bg1.jpeg"),
        require("@/assets/images/card-event-bg2.jpeg"),
        require("@/assets/images/card-event-bg1.jpeg"),
      ],
    };
  },
  watch: {
    "dashboardObj.dailyVerse": {
      handler(val) {
        if (val) {
          this.pageObj.modal.enable = true;
        }
      },
    },
    dashboardObj(currentVal, prevVal) {
      this.dashboardObj = currentVal;
    },
  },
  created() {
    EventBus.$on("pull-refresh", (data) => {
      this.getAllData({
        services: [data.type],
      });
    });
    
    EventBus.$on("pull-refresh", (data) => {
      this.getAllData({
        services: [data.type],
      });
    });
    this.getAllData({
      services: [
        "events",
        "dailyMessage",
        "articles",
        "myEvents",
        "prayers",
        "notifications",
        "polls",
      ],
      dailyVerse: this.dateInjected,
    });
  },
  beforeDestroy() {
    this.pageObj.modal.enable = false;
    // EventBus.$off("refresh-data", this.getAllData());
  },
  computed: {
    isLiked() {
      return this.dashboardObj.dailyMessage.likes?.includes(
        this.getActiveUserGuid
      );
    },
    verse() {
      return store.getters["userStore/userData"].dailyVerse;
    },
    dateInjected() {
      return moment(
        store.getters["userStore/userData"]?.dailyVerse?.dateInjected ||
          moment().subtract(1, "days").format("LL")
      ).isBefore(moment().format("LL"));
    },
    getUserName() {
      const { name } = store.getters["userStore/userData"] || { name: "" };

      return name;
    },
    getUserRole() {
      const { role = "" } = store.getters["appStore/appData"] || {};

      return role;
    },
    getPromiseVerse() {
      const { promiseVerse } = store.getters["userStore/userData"] || {};

      return promiseVerse;
    },
    getArticles() {
      return this.dashboardObj.articles.slice(0, 5);
    },
  },
  methods: {
    updateApp() {
      this.$router.push({
        name: 'Update'
      })
    },
    setDailyVerseDate() {
      store.dispatch("userStore/setUserData", {
        dailyVerse: {
          ...store.getters["userStore/userData"]?.dailyVerse,
          dateInjected: moment().format("LL"),
        },
      });
      this.dashboardObj.dailyVerse =
        store.getters["userStore/userData"]?.dailyVerse;
    },
    getAllData({ services, dailyVerse = false } = {}) {
      this.getDashboardData({ services, dailyVerse });
    },
    handleShare(url) {
      try {
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], `verse.jpg`, { type: "image/jpeg" });
            const filesArray = [file];

            if (navigator.share) {
              navigator
                .share({
                  text: !this.isAppleDevice ? this.verse.reference : "",
                  files: filesArray,
                  title: !this.isAppleDevice ? "Daily Verse" : "",
                })
                .then((data) => (this.activateShare = false))
                .catch((e) => (this.activateShare = false));
            }
          });
        this.activateShare = false;
      } catch (e) {
        console.log(`Error in handleShare`, e);
      }
    },
    async likeActivity(id, likeStatus) {
      try {
        const { likes = 0 } = await commonService.likeActivity(
          id,
          !likeStatus ? "like" : "unlike"
        );

        if (likes) {
          this.dashboardObj.dailyMessage.likes.push(this.getActiveUserGuid);
          this.dashboardObj.dailyMessage = {
            ...this.dashboardObj.dailyMessage,
            likeCount:
              this.dashboardObj.dailyMessage.likeCount + (!likeStatus ? 1 : -1),
          };
        }
      } catch (e) {
        console.log(`Error in likeActivity ${e}`);
      }
    },
    handleEventClick(params) {
      this.$router.push({
        name: "Event-Detail",
        params,
        meta: {
          fromRoute: "home",
        },
      });
    },
    handleMessageClick(params) {
      this.$router.push({
        name: "Content",
        params,
      });
    },
    async getDashboardData({ services, dailyVerse = false }) {
      try {
        const timeoutPromise = new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              items: [],
            }); // Resolve with null after the timeout
          }, 3000);
        });

        this.pageObj.isServiceCallActive = true;
        if (!services) {
          return;
        }
        const promises = {
          events: commonService.getEvents(),
          dailyMessage: commonService.getDailyMessages(),
          articles: commonService.getArticles(),
          myEvents: commonService.myEvents(),
          notifications: commonService.getNotifications({
            limit: 10,
            offset: 0,
          }),
          prayers: commonService.getPrayers(),
          polls: commonService.getPolls({ isPublic: true }),
        };
        if (dailyVerse) {
          Object.assign(promises, {
            dailyVerse: commonService.getPromiseVerseHome(),
          });
          services.push("dailyVerse");
        }
        const promiseToResolve = services.map((s) => promises[s]);
        const response = await Promise.all(promiseToResolve);
        const result = {};

        if (response) {
          this.pageObj.isServiceCallActive = false;
          services.forEach((s, index) => {
            result[s] = response[index]?.items;
          });

          this.dashboardObj = {
            ...this.dashboardObj,
            ...(result.events && { events: result.events }),
            ...(result.dailyMessage && {
              dailyMessage: result.dailyMessage[0],
            }),
            ...(result.articles && { articles: result.articles }),
            ...(result.myEvents && { myEvents: result.myEvents }),
            ...(result.prayers && { prayers: result.prayers }),
            ...(result.polls && { prayers: result.polls }),
            ...(result.dailyVerse && { dailyVerse: result.dailyVerse }),
          };

          store.dispatch("userStore/setUserData", {
            ...(result.myEvents && { myEvents: result.myEvents }),
            ...(result.dailyVerse && {
              dailyVerse: {
                ...result.dailyVerse,
              },
            }),
            ...(result.notifications && {
              notifications: result.notifications,
            }),
            ...(result.prayers && { prayers: result.prayers }),
            ...(result.polls && { polls: result.polls }),
            ...(result.articles && { articles: result.articles }),
          });
          this.reRenderSwiper = false;
          this.$nextTick().then(() => {
            this.reRenderSwiper = true;
          });
        }
      } catch (e) {
        console.log(`Error in getEvents`, e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.event-card {
  background-position: center !important;
}
.article-quotedText {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 25px;
}
::v-deep .v-alert--prominent .v-alert__icon {
  align-self: center;
  height: 40px;
  min-width: 40px;
}
</style>
