import Vue from 'vue'
import App from '@/App.vue'
// import '@/registerServiceWorker'
import router from '@/_core/router'
import store from '@/_core/store'
import vuetify from '@/plugins/vuetify'
import appSettings from '@/plugins/appSettings'
import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import CKEditor from "@ckeditor/ckeditor5-vue2";  
import VueHtml2Canvas from 'vue-html2canvas';
import 'core-js/stable'; 
import 'regenerator-runtime/runtime';
// import VueNativeNotification from 'vue-native-notification'
import './registerServiceWorker'

// Vue.use(VueNativeNotification, {
//   requestOnNotify: true
// })
Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDbFeJZk5gLXHwjOG1sgSM4prPvuIA12d4',
    libraries: 'places',
  },
})
Vue.use(VueHtml2Canvas);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyDbFeJZk5gLXHwjOG1sgSM4prPvuIA12d4', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});
Vue.use(CKEditor);
Vue.prototype.$appSettings = appSettings

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
