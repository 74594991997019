import store from "@/_core/store"

const appCommonMixin = {
  computed: {
    historyPath() {
      return store.getters['appStore/appData'].historyPath || 'Home'
    },
    getActiveUserGuid() {
      return store.getters['userStore/userData'].guid
    },
    getActiveUserEmail() {
      return store.getters['userStore/userData'].email
    },
    getActiveUserName() {
      return store.getters['userStore/userData'].name
    },
    getActiveUserPhone() {
      return store.getters['userStore/userData'].phone
    },
    getChurchFullName() {
      try {
        const {
          church: { fullName: churchFullName } = {},
        } = store.getters["userStore/userData"] || { church: { fullName: '' } };

        return churchFullName;
      } catch (e) {
        console.log(`Error in getChurchFullName`, e);
      }
    },
    getChurchEmail() {
      try {
        const {
          church: { email } = {},
        } = store.getters["userStore/userData"] || { church: { email: '' } };

        return email;
      } catch (e) {
        console.log(`Error in getChurchEmail`, e);
      }
    },
    getChurchPhone() {
      try {
        const {
          church: { phone } = {},
        } = store.getters["userStore/userData"] || { church: { phone: '' } };

        return phone;
      } catch (e) {
        console.log(`Error in getChurchPhone`, e);
      }
    },
    getChurchAddress() {
      try {
        const {
          church: { address } = {},
        } = store.getters["userStore/userData"] || { church: { address: '' } };

        return address;
      } catch (e) {
        console.log(`Error in getChurchAddress`, e);
      }
    },
    getChurchName() {
      try {
        const {
          church: { name: churchName } = {},
        } = store.getters["userStore/userData"] || { church: { name: '' } };

        return churchName;
      } catch (e) {
        console.log(`Error in getChurchName`, e);
      }
    },
    getChurchLogo() {
      try {
        const {
          church: { logo: churchLogo } = {},
        } = store.getters["userStore/userData"]

        return `data:image/jpeg;base64,${churchLogo}`;
      } catch (e) {
        console.log(`Error in getChurchLogo`, e);
      }
    },
    actionName() {
      return this.$route.params.action === "edit" ? "Update" : "Add";
    },
    isNew() {
      return this.$route.params.action == "new";
    },
    isAppleDevice() {
      return window.navigator.userAgent.includes("AppleWebKit");
    },
    isRoleAdmin() {
      return this.$store.getters["appStore/appData"].role === "admin";

    }
  },
  methods: {
    convertToBase64(file) {
      if (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = reject
        })
      }
    },
    logoutUser() {
      try {
        const churchCode = this.$store.getters["userStore/userData"].church.code;

        this.$store.dispatch("userStore/removeUserData", {});
        // this.$store.dispatch("appStore/removeAppData", {});
        this.$router.push({
          name: "Login",
          params: {
            churchCode,
          },
        });
      } catch (e) {
        console.log(`Error in logoutUser ${e}`);
      }
    }
  }
}

export default appCommonMixin