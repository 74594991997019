<template>
  <v-bottom-navigation
    app
    fixed
    grow
    v-model="activeMenuItem"
    color="#52c2cd"
    v-if="getMainMenu"
  >
    <v-btn
      :value="menuObj.name"
      @click="handleMenuClick({ name: menuObj.name })"
      v-for="(menuObj, index) in getMainMenu"
      :key="index"
    >
      <span>{{ menuObj.name }}</span>
      <v-icon>{{ menuObj.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import router from "@/_core/router";

export default {
  name: "bottomnav",
  data() {
    return {
      menuConfiguration: [],
      activeMenuItem: "",
    };
  },
  computed: {
    getMainMenu() {
      return this.$store.getters["appStore/appData"].menu;
    },
  },
  watch: {
    getMainMenu(currentVal) {
      this.menuConfiguration = currentVal;
    },
  },
  methods: {
    handleMenuClick({ name }) {
      this.activeMenuItem = name;
      router
        .push({
          name,
          // params: {},
        })
        .catch((e) => e);
    },
  },
};
</script>