import store from '@/_core/store'

const handleError = (e = {}) => {
    const { message: content } = e
    const exclusionMessages = ["Cannot read properties of undefined (reading 'data')"]
    const defaultMessage = "Sorry, app encountered an error. Please try again"
    store.dispatch("appStore/setAppData", { inProgress: false })

    store.dispatch("appStore/setAppData", {
        message: {
            content: exclusionMessages.includes(content) ? defaultMessage : content,
            active: true,
        },
    });
}

export default handleError