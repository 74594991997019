import axios from 'axios'
import store from '@/_core/store'
import Settings from '@/plugins/appSettings'
import router from '@/_core/router'

const { code: churchCode = '' } = store.getters["userStore/userData"].church || {}

const removeStoredData = () => {
    store.dispatch("userStore/removeUserData", {});
    store.dispatch("appStore/removeAppData", {});
}
const getRefreshToken = () => {
    return Api({
        Authorization: undefined
    })
        .post('ext/refresh-token', {
            refresh_token: store.getters['userStore/userData'].refreshToken,
            "grant_type": "refresh_token"
        })
        .then(({ data }) => data)
        .catch(e => {
            router.push({
                name: 'Login',
                params: {
                    churchCode: store.getters["userStore/userData"].church?.code
                }
            }).catch(e => console.log(e))
            removeStoredData()
        })
}

const Api = (headerObj = {}) => {
    try {
        const headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${store.getters['userStore/userData'].idToken}`,
            tenant: store.getters["userStore/userData"].church ? store.getters["userStore/userData"].church?.name : '',
            'Content-Type': 'application/json',
            ...headerObj
        }
        const axiosApi = axios.create({
            baseURL: Settings.baseUrl,
            headers
        })

        axiosApi.interceptors.request.use((config) => {
            store.dispatch("appStore/setAppData", { inProgress: config.headers.disableProgess !== 'true' })

            return config
        })

        axiosApi.interceptors.response.use((response) => {
            store.dispatch("appStore/setAppData", { inProgress: false })
            return response
        }, async ({ config, response }) => {
            if (response?.status === 403 && !config.url.includes('refresh-token')) {
                const retryOriginalRequest = new Promise(async (resolve) => {
                    const {
                        idToken,
                        refreshToken,
                        accessToken,
                        expiresIn,
                    } = await getRefreshToken() || {}

                    if (idToken && refreshToken) {
                        store.dispatch("userStore/setUserData", {
                            idToken,
                            refreshToken,
                            accessToken,
                            expiresIn,
                        });

                        if (config) {
                            config.headers.Authorization = `Bearer ${idToken}`
                            resolve(axios(config))
                        }
                    }
                })

                return retryOriginalRequest
            }

            return Promise.reject({ ...response.data })
        })

        return axiosApi
    } catch (e) {
        console.log(`Error in interceptor`, e);
    }
}

export {
    Api
}